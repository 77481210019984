import React from "react"
import PropTypes from "prop-types"
import { BgImage } from "gbimage-bridge"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from '../../assets'
import styled from 'styled-components'

const Banner = props => {
  const {
    image,
    imageMobile,
    heading,
    description,
    description2,
  } = props

  const bannerImages = [
    imageMobile.gatsbyImageData,
    {
      ...image.gatsbyImageData,
      media: `(min-width: 600px)`,
    },
  ]

  return (
    <BannerContent container>
      <BannerBack
        image={bannerImages}
      >
        <h1>{heading}</h1>
        <p>{description}</p>
        <p>{description2}</p>
      </BannerBack>
    </BannerContent>
  )
}

const BannerContent = styled(Grid)`
  background-image: linear-gradient(
    135deg,
    ${ colors.TETRA_BANNER_LIGHT } 0%,
    ${ colors.TETRA_BANNER_LIGHT } 30%,
    ${ colors.TETRA_BANNER_DARK } 70%,
    ${ colors.TETRA_BANNER_DARK } 100%
  );
`

const BannerBack = styled(BgImage)`
  width: 100%;
  padding: 30px 20px 10px;
  background-position: top right;
  background-size: cover;


  ${ mediaQueries.SM_UP } {
    padding: 30px 30px 10px;
    background-position: bottom center;
  }

  ${ mediaQueries.MD_UP } {
    padding: 30px 30px 20px;
  }

  ${ mediaQueries.LG_UP } {
    padding: 85px 75px;
  }

  h1,
  h2,
  p {
    color: ${ colors.TETRA_WHITE };

    ${ mediaQueries.SM_UP } {
      width: 100%;
    }

    ${ mediaQueries.LG_UP } {
      width: 50%;
    }
  }

  h2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    ${ mediaQueries.SM_UP } {
      font-size: 24px;
      line-height: 32px;
      margin: 30px 0 40px;
    }
  }

  p {
    font-size: 14px;
    line-height: 24px;

    ${ mediaQueries.SM_UP } {
      max-width: 520px;
      font-size: 16px;
    }
  }
`

Banner.propTypes = {
  image: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  description2: PropTypes.string.isRequired,
}

export default Banner
