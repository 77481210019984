import React, { useState } from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Container from "../container"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from "../../assets"
import styled from "styled-components"

const Team = props => {
  const { heading, team } = props

  const [bios, setBios] = useState({
    member1: false,
    member2: false,
    member3: false,
    member4: false,
  })

  const updateBios = e => {
    setBios({
      ...bios,
      [e.target.id]: !bios[e.target.id]
    })
  }

  return (
    <Container background={colors.TETRA_WHITE}>
      <TeamContainer container>
        <Heading item xs={12}>
          <h2>{heading}</h2>
        </Heading>
        {team.map((member, i) => (
          <Members key={i} container item xs={12} sm={6} md={3}>
            <Member>
              <Image item xs={12}>
                <GatsbyImage
                  image={member.image.childImageSharp.gatsbyImageData}
                  alt={`${ member.name } - Tetra Trust Company`}
                  className={member.image.name}
                />
              </Image>
              <Description item xs={12}>
                <h4>{member.name}</h4>
                <p>{member.position}</p>
              </Description>
              <ShowBio
                id={`member${ i + 1 }`}
                onClick={updateBios}
              >
                Read More
              </ShowBio>
            </Member>
            <BioContainer>
              {bios[`member${ i + 1 }`] &&
                <Bio>
                  <button
                    id={`member${ i + 1 }`}
                    onClick={e => {
                      e.stopPropagation()
                      updateBios(e)
                    }}
                  >
                    x
                  </button>
                  {member.bio}
                </Bio>}
            </BioContainer>
          </Members>
        ))}
      </TeamContainer>
    </Container>
  )
}

const TeamContainer = styled(Grid)`
  ${ mediaQueries.CONTAINER_SIZES }
  justify-content: center;
  width: 100%;
  padding: 35px 20px 0;

  ${ mediaQueries.SM_UP } {
    width: 500px;
  }

  ${ mediaQueries.MD_UP } {
    width: 100%;
    padding: 60px 30px 35px;
  }
`

const Heading = styled(Grid)`
  h2 {
    max-width: 290px;
    margin: 0 auto 30px;
    text-align: center;

    ${ mediaQueries.MD_DOWN } {
      font-size: 24px;
      line-height: 32px;
    }

    ${ mediaQueries.MD_UP } {
      max-width: none;
      margin: 0 auto 45px;
    }
  }
`

const Members = styled(Grid)`
  justify-content: center;
  margin: 0 0 30px;
`

const Member = styled(Grid)`
  text-align: center;
`

const Image = styled(Grid)`
  max-width: 96px;
  margin: 0 auto;

  ${ mediaQueries.MD_UP } {
    max-width: 136px;
  }

  .gatsby-image-wrapper {
    border-radius: 50%;
  }
`

const Description = styled(Grid)`
  h4 {
    margin: 15px 0 0;
    font-size: 16px;
    line-height: 24px;

    ${ mediaQueries.MD_UP } {
      margin: 20px 0 0;
      font-size: 20px;
      line-height: 40px;
    }
  }

  p {
    max-width: 270px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;

    ${ mediaQueries.MD_UP } {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

const ShowBio = styled.button`
  margin: 5px auto 0;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  text-decoration: underline;
`

const BioContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
`

const Bio = styled.div`
  position: absolute;
  top: 10px;
  z-index: 9999;
  padding: 15px 30px 15px 15px;
  background: ${ colors.TETRA_WHITE };
  border: 1px solid ${ colors.TETRA_GREY };
  border-radius: 4px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 20px;
  animation: fadein 0.3s;

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  button {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 16px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
  }
`

Team.propTypes = {
  heading: PropTypes.string.isRequired,
  team: PropTypes.array.isRequired,
}

export default Team
