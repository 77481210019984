import React from "react"
import PropTypes from "prop-types"
import Container from "../container"
import ButtonA from "../button-a"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from "../../assets"
import styled from "styled-components"

const SignUp = props => {
  const { heading, cta, ctaLink } = props

  return (
    <Container background={colors.tetraBannerLight}>
      <SignUpContainer container>
        <Heading item xs={12}>
          <h2>{heading}</h2>
          <ButtonA
            buttontype="white"
            to={ctaLink}
            text={cta}
            maxWidth="250px"
            margin="20px auto 0"
            target="_blank"
          />
        </Heading>
      </SignUpContainer>
    </Container>
  )
}

const SignUpContainer = styled(Grid)`
  ${ mediaQueries.CONTAINER_SIZES }
  justify-content: space-between;
  padding: 40px 20px;

  ${ mediaQueries.SM_UP } {
    padding: 60px 30px 25px;
  }
`

const Heading = styled(Grid)`
  text-align: center;

  ${ mediaQueries.SM_UP } {
    margin: 0 0 50px;
  }

  h2 {
    color: ${ colors.tetraWhite };
  }
`

SignUp.propTypes = {
  heading: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
}

export default SignUp
