import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/about-us/banner"
import Team from "../components/about-us/team"
import Board from "../components/about-us/board"
import Careers from "../components/about-us/careers"

const AboutUsPage = ({ data }) => {
  const { aboutUs } = data.markdownRemark
  
  return (
    <Layout>
      <Seo
        title={aboutUs.seoTitle}
        description={aboutUs.seoDescription}
        image={aboutUs.seoImage.publicURL}
      />

      <Banner
        image={aboutUs.bannerImage.childImageSharp}
        imageMobile={aboutUs.bannerImageMobile.childImageSharp}
        heading={aboutUs.bannerHeading}
        description={aboutUs.bannerDescription}
        description2={aboutUs.bannerDescription2}
      />

      <Team
        heading={aboutUs.teamHeading}
        team={aboutUs.team}
      />

      <Board
        heading={aboutUs.boardHeading}
        board={aboutUs.board}
      />

      <Careers
        heading={aboutUs.careersHeading}
        cta={aboutUs.careersCta}
        ctaLink={aboutUs.careersCtaLink}
      />
    </Layout>
  )
}

export default AboutUsPage

export const query = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/about-us" } }) {
      aboutUs: frontmatter {
        seoTitle
        seoDescription
        seoImage {
          publicURL
        }
        bannerImage {
          childImageSharp {
            gatsbyImageData(width: 650 quality: 100)
          }
        }
        bannerImageMobile {
          childImageSharp {
            gatsbyImageData(width: 650 quality: 100)
          }
        }
        bannerHeading
        bannerDescription
        bannerDescription2
        teamHeading
        team {
          name
          position
          image {
            childImageSharp {
              gatsbyImageData(width: 300 quality: 100)
            }
          }
          bio
        }
        boardHeading
        board {
          name
          position
          image {
            childImageSharp {
              gatsbyImageData(width: 300 quality: 100)
            }
          }
          linkedinUrl
        }
        careersHeading
        careersCta
        careersCtaLink
      }
    }
  }
`
