import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Container from "../container"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from "../../assets"
import styled from "styled-components"

const Board = props => {
  const { heading, board } = props

  return (
    <Container background={colors.TETRA_WHITE}>
      <BoardContainer container>
        <Heading item xs={12}>
          <h2>{heading}</h2>
        </Heading>
        {board.map((member, i) => (
          <Members key={i} container item xs={12} sm={4}>
            <Member>
              <Image item xs={12}>
                <GatsbyImage
                  image={member.image.childImageSharp.gatsbyImageData}
                  alt={`${ member.name } - Tetra Trust Company`}
                  className={member.image.name}
                />
              </Image>
              <Description item xs={12}>
                <h4>{member.name}</h4>
                <p>{member.position}</p>
              </Description>
              <LinkedinUrl
                href={member.linkedinUrl}
                target="_blank"
              >
                Read More
              </LinkedinUrl>
            </Member>
          </Members>
        ))}
      </BoardContainer>
    </Container>
  )
}

const BoardContainer = styled(Grid)`
  ${ mediaQueries.CONTAINER_SIZES }
  justify-content: center;
  width: 100%;
  padding: 35px 20px 30px;

  ${ mediaQueries.SM_UP } {
    width: 700px;
  }

  ${ mediaQueries.MD_UP } {
    width: 100%;
    padding: 60px 30px 0;
  }
`

const Heading = styled(Grid)`
  h2 {
    max-width: 290px;
    margin: 0 auto 30px;
    text-align: center;

    ${ mediaQueries.MD_DOWN } {
      font-size: 24px;
      line-height: 32px;
    }

    ${ mediaQueries.MD_UP } {
      max-width: none;
      margin: 0 auto 45px;
    }
  }
`

const Members = styled(Grid)`
  justify-content: center;
  margin: 0 0 30px;

  ${ mediaQueries.MD_UP } {
    margin: 0 0 80px;
  }
`

const Member = styled(Grid)`
  text-align: center;
`

const Image = styled(Grid)`
  max-width: 96px;
  margin: 0 auto;

  ${ mediaQueries.MD_UP } {
    max-width: 136px;
  }

  .gatsby-image-wrapper {
    border-radius: 50%;
  }
`

const Description = styled(Grid)`
  h4 {
    margin: 15px 0 0;
    font-size: 16px;
    line-height: 24px;

    ${ mediaQueries.MD_UP } {
      margin: 20px 0 0;
      font-size: 20px;
      line-height: 40px;
    }
  }

  p {
    max-width: 270px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;

    ${ mediaQueries.MD_UP } {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

const LinkedinUrl = styled.a`
  margin: 5px auto 0;
  background: none;
  border: none;
  color: ${ colors.TETRA_BLACK };
  font-size: 14px;
  cursor: pointer;
  outline: none;
  text-decoration: underline;
`

Board.propTypes = {
  heading: PropTypes.string.isRequired,
  board: PropTypes.array.isRequired,
}

export default Board
